import React, { useRef, useState, useEffect, RefObject } from "react";
import { NavBar } from "../../components/NavBar";
import { IPhoto, EStatus, IEvent } from "../../models/Amphetrite.models";
import { AccountService } from "../../services/Account.service";

const months = ["Jan", "Feb", "Mar", "Apr", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const AdminEvents = (props: any) => {
  const fileRef: RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);
  const docRef: RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);

  const today = () => {
    const date = new Date();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    return `${date.getFullYear()}-${month}-${date.getDate().toString().padStart(2, '0')}T10:00`;
  }

  const [state, setState] = useState<{
    error: boolean;
    errorMsg: string;
    photo: any;
    key: number;
    status: EStatus;
    events: IEvent[];
    eventName: string;
    eventDescription: string;
    startDate: string;
    endDate: string;
  }>({
    photo: null,
    events: [],
    error: false,
    key: 0,
    errorMsg: "",
    status: EStatus.IDLE,
    endDate: today(),
    eventDescription: "",
    eventName: "",
    startDate: today(),
  });

  useEffect(() => {
    loadEvents();
  }, [props]);

  const loadEvents = () => {
    AccountService.getEvents()
      .then((resp) => resp.json())
      .then((json) => {
        if (json.success) {
          setState({
            ...state,
            events: json.data,
          });
        } else {
          setState({
            ...state,
            error: true,
            events: [],
            errorMsg: json.message,
          });
        }
      })
      .catch((error) => {
        setState({
          ...state,
          error: true,
          errorMsg: "Network error. Please refresh or press F5...",
        });
      });
  };

  const onFileSelect = (e: any) => {
    const { files } = e.target;
    setState({
      ...state,
      photo: files[0],
    });
  };

  const onInputChange = (e: any) => {
    const { value, name } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const clearStatus = () =>
    setState({
      ...state,
      status: EStatus.IDLE,
    });

  const uploadPhoto = () => {
    console.log(state);
    
    if (!state.photo) {
      fileRef.current && fileRef.current.reportValidity();
      return;
    }
    setState({
      ...state,
      error: false,
      status: EStatus.UPLOADING,
    });
    AccountService.uploadEvent({
      eventName: state.eventName,
      eventDescription: state.eventDescription,
      startDate: state.startDate,
      endDate: state.endDate,
      fileToUpload: state.photo,
    })
      .then((resp) => resp.json())
      .then((json) => {
        if (json.success) {
          setState({
            ...state,
            key: state.key + 1,
            photo: null,
            error: false,
            status: EStatus.UPLOAD_SUCCESS,
            events: [...state.events, json.data],
          });
          // setTimeout(clearStatus, 3000);
        } else {
          setState({
            ...state,
            error: true,
            status: EStatus.IDLE,
            errorMsg: json.message,
          });
        }
      })
      .catch((error) => {
        setState({
          ...state,
          error: true,
          status: EStatus.IDLE,
          errorMsg: "Network error. Please try again...",
        });
      });
  };

  const deletePhoto = (event: IEvent) => {
    AccountService.deleteEvent(event.eventId)
      .then((resp) => resp.json())
      .then((json) => {
        if (json.success) {
          setState({
            ...state,
            status: EStatus.DELETE_SUCCESS,
          });
          loadEvents();
        } else {
          setState({
            ...state,
            error: true,
            errorMsg: json.message,
          });
        }
      })
      .catch((error) => {
        setState({
          ...state,
          error: true,
          status: EStatus.IDLE,
          errorMsg: "Network error. Please try again...",
        });
      });
  };

  const formatTime = (time: string) => {
    
    const date = new Date(time);
    console.log(time, date);
    let meridiam = 'AM IST';
    let hours = date.getHours();
    if (hours > 12) {
      meridiam = 'PM IST';
      hours = hours - 12;
    }
    let minutes = date.getMinutes().toString().padStart(2, '0');
    return `${date.getDate()}-${months[date.getMonth() - 1]}-${date.getFullYear()} ${hours.toString().padStart(2, '0')}:${minutes} ${meridiam}`;
  }

  return (
    <div ref={docRef}>
      <NavBar {...props} />
      <div className="container">
        <div className="jumbotron row mt-3">
          <div className="col-md-4 mb-2">
            Event Title
            <input
              onChange={onInputChange}
              name="eventName"
              value={state.eventName}
              placeholder="Event Title"
              className="form-control"
            />
          </div>
          <div className="col-md-4 mb-2">
            Start Date
            <input
              onChange={onInputChange}
              name="startDate"
              type="datetime-local"
              min={state.startDate}
              value={state.startDate}
              placeholder="Start Date"
              className="form-control"
            />
          </div>
          <div className="col-md-4 mb-2">
            End Date
            <input
              onChange={onInputChange}
              name="endDate"
              min={state.startDate}
              type="datetime-local"
              value={state.endDate}
              placeholder="End Date"
              className="form-control"
            />
          </div>
          <div className="col-md-12 mb-2">
            Event Description
            <textarea
              name="eventDescription"
              className="form-control"
              onChange={onInputChange}
              value={state.eventDescription}
            ></textarea>
          </div>
          <div className="col-md-6 col-xs-12 form-group">
            Select a picture
            <input
              ref={fileRef}
              key={`${state.key}`}
              required
              type="file"
              name="categoryName"
              onChange={onFileSelect}
              className="form-control"
              placeholder="Category"
            />
          </div>

          <div className="col-md-6 col-xs-12 form-group mt-4">
            <button
              type="button"
              disabled={state.status === EStatus.UPLOADING}
              onClick={uploadPhoto}
              className="btn btn-primary btn-block"
            >
              Upload
            </button>
          </div>
          {state.error && (
            <label className="ml-3 text-danger">{state.errorMsg}</label>
          )}
          {state.status === EStatus.UPLOADING && (
            <label className="ml-3 text-info">Uploading...</label>
          )}
          {state.status === EStatus.UPLOAD_SUCCESS && (
            <label className="ml-3 text-success">
              Event uploaded successfully...
            </label>
          )}
          {state.status === EStatus.TOGGLE_SUCCESS && (
            <label className="ml-3 text-success">
              Event updated successfully...
            </label>
          )}
          {state.status === EStatus.DELETE_SUCCESS && (
            <label className="ml-3 text-success">
              Event deleted successfully...
            </label>
          )}
        </div>
        <div className="row">
          {state.events.map((pic, index) => (
            <div key={index} className="thumbnail col-md-6 col-xs-12 mb-2">
              <div className="photo">
                <img className="img" src={pic.imageURL} alt="Photos" />
                <div className="col-xs-12 mt-2">
                  <h4><strong>{pic.eventName}</strong></h4>
                  <div className="row p-3 justify-content-between">
                    <span>From: <strong>{formatTime(pic.startDate)}</strong></span>
                    <span>To: <strong>{formatTime(pic.endDate)}</strong></span>
                  </div>
                  <p className="mt-2">{pic.eventDescription}</p>
                </div>
                <div className="text-right mt-1">

                  <button
                    onClick={() => deletePhoto(pic)}
                    className="btn btn-danger"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
