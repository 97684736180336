import React, { useState, useEffect, useRef } from "react";
import { PhotoService } from "../services/Photo.service";
import { IPhoto } from "../models/Amphetrite.models";
import { SideBar } from "../components/SideBar";

export const Portfolio = (props: any) => {
  const categRef = useRef<HTMLInputElement>(null);

  const [state, setState] = useState<{
    error: boolean;
    errorMsg: string;
    categories: IPhoto[];
  }>({
    error: false,
    errorMsg: "",
    categories: [],
  });

  const getCategories = () => {
    PhotoService.getCategories()
      .then(resp => resp.json())
      .then(json => {
        if (json.success) {
          setState({
            ...state,
            categories: json.data,
          });
        } else {
          setState({
            ...state,
            error: true,
            errorMsg: json.message,
          });
        }
      })
      .catch(error => {
        console.error(error);
        setState({
          ...state,
          error: true,
          errorMsg: "Network error. Please try again.",
        });
      });
  };

  useEffect(() => {
    getCategories();
  }, [props]);

  const goToCategory = (photoCateg: IPhoto) => {
    props.history.push("/gallery", { photoCateg });
  };

  return (
    <div className="container-fluid row no-gutters">
      <div className="col-lg-auto">
        <SideBar />
      </div>
      <div className="col-md-9 mt-3">
        <div>
          <h2>Portfolio</h2>
        </div>
        <div className="row">
          {state.categories.map((category, index) => (
            <div
              key={index}
              onClick={() => goToCategory(category)}
              className="thumbnail col-md-6 col-xs-12 mb-2"
            >
              <div className="category">
                <img className="img" src={category.photoURL} alt="Photos" />
                <div className="row">
                  <div className="col-md-6 col-xs-12 mt-3">
                    <label className="">{category.categName}</label>
                  </div>
                  <div className="col-md-6 col-xs-12 counts-container mt-3">
                    <label className="counts">
                      <img
                        src="/images/ic_heart.png"
                        className="icon"
                        alt="Heart"
                      />
                      {category.likes}
                    </label>
                    <label className="counts">
                      <img
                        src="/images/ic_eye.png"
                        className="icon"
                        alt="Heart"
                      />
                      {category.views}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
