import React, { useRef, useState, useEffect, RefObject } from "react";
import { NavBar } from "../../components/NavBar";
import { IPhoto, EStatus } from "../../models/Amphetrite.models";
import { PhotoService } from "../../services/Photo.service";

export const Category = (props: any) => {
  const fileRef: RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);
  const docRef: RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);

  const [state, setState] = useState<{
    error: boolean;
    errorMsg: string;
    photo: any;
    showInBG: boolean;
    key: number;
    status: EStatus;
    photos: IPhoto[];
  }>({
    photo: null,
    photos: [],
    error: false,
    showInBG: false,
    key: 0,
    errorMsg: "",
    status: EStatus.IDLE,
  });

  useEffect(() => {
    loadImages();
  }, [props]);

  const loadImages = () => {
    PhotoService.getPhotosByCategories(photoCateg.categId)
      .then((resp) => resp.json())
      .then((json) => {
        if (json.success) {
          setState({
            ...state,
            photos: json.data,
          });
        } else {
          setState({
            ...state,
            error: true,
            photos: [],
            errorMsg: json.message,
          });
        }
      })
      .catch((error) => {
        setState({
          ...state,
          error: true,
          errorMsg: "Network error. Please refresh or press F5...",
        });
      });
  };

  const { photoCateg } = props.location.state;

  const onFileSelect = (e: any) => {
    const { files } = e.target;
    setState({
      ...state,
      photo: files[0],
    });
  };

  const clearStatus = () =>
    setState({
      ...state,
      status: EStatus.IDLE,
    });

  const uploadPhoto = () => {
    if (!state.photo) {
      fileRef.current && fileRef.current.reportValidity();
      return;
    }
    setState({
      ...state,
      error: false,
      status: EStatus.UPLOADING,
    });
    PhotoService.uploadPhoto({
      category: photoCateg.categId,
      showInBG: state.showInBG,
      fileToUpload: state.photo,
    })
      .then((resp) => resp.json())
      .then((json) => {
        if (json.success) {
          setState({
            ...state,
            key: state.key + 1,
            photo: null,
            showInBG: false,
            status: EStatus.UPLOAD_SUCCESS,
            photos: [...state.photos, json.data],
          });
          // setTimeout(clearStatus, 3000);
        } else {
          setState({
            ...state,
            error: true,
            status: EStatus.IDLE,
            errorMsg: json.message,
          });
        }
      })
      .catch((error) => {
        setState({
          ...state,
          error: true,
          status: EStatus.IDLE,
          errorMsg: "Network error. Please try again...",
        });
      });
  };

  const deletePhoto = (photo: IPhoto) => {
    PhotoService.deletePhoto(photo.photoId, photo.photoURL)
      .then((resp) => resp.json())
      .then((json) => {
        if (json.success) {
          setState({
            ...state,
            status: EStatus.DELETE_SUCCESS,
          });
          loadImages();
        } else {
          setState({
            ...state,
            error: true,
            errorMsg: json.message,
          });
        }
      })
      .catch((error) => {
        setState({
          ...state,
          error: true,
          status: EStatus.IDLE,
          errorMsg: "Network error. Please try again...",
        });
      });
  };

  const onCheckChange = (e: any) => {
    console.log(e.target.checked);
    setState({
      ...state,
      showInBG: e.target.checked,
    });
  };

  const toggleBG = (photo: IPhoto) => {
    const { photoId = 0 } = photo;
    const value = photo.showInBG === "1" ? "0" : "1";
    PhotoService.toggleBG(photoId, value)
      .then((resp) => resp.json())
      .then((json) => {
        if (json.success) {
          loadImages();
          setState({
            ...state,
            status: EStatus.TOGGLE_SUCCESS,
          });
        } else {
          setState({
            ...state,
            error: true,
            errorMsg: json.message,
          });
        }
      })
      .catch((error) => {
        setState({
          ...state,
          error: true,
          status: EStatus.IDLE,
          errorMsg: "Network error. Please try again...",
        });
      });
  };

  return (
    <div ref={docRef}>
      <NavBar {...props} />
      <div className="container">
        <div className="jumbotron row mt-3">
          <div className="col-md-6 col-xs-12 form-group">
            <input
              ref={fileRef}
              key={`${state.key}`}
              required
              type="file"
              name="categoryName"
              onChange={onFileSelect}
              className="form-control"
              placeholder="Category"
            />
          </div>
          <div className="col-md-3 col-xs-12 form-group">
            <input
              type="checkbox"
              onChange={onCheckChange}
              name="showInBG"
              checked={state.showInBG}
            />
            Show in Background
          </div>
          <div className="col-md-3 col-xs-12 form-group">
            <button
              type="button"
              disabled={state.status === EStatus.UPLOADING}
              onClick={uploadPhoto}
              className="btn btn-primary btn-block"
            >
              Upload
            </button>
          </div>
          {state.error && (
            <label className="ml-3 text-danger">{state.errorMsg}</label>
          )}
          {state.status === EStatus.UPLOADING && (
            <label className="ml-3 text-info">Uploading...</label>
          )}
          {state.status === EStatus.UPLOAD_SUCCESS && (
            <label className="ml-3 text-success">
              Photo uploaded successfully...
            </label>
          )}
          {state.status === EStatus.TOGGLE_SUCCESS && (
            <label className="ml-3 text-success">
              Photo updated successfully...
            </label>
          )}
          {state.status === EStatus.DELETE_SUCCESS && (
            <label className="ml-3 text-success">
              Photo deleted successfully...
            </label>
          )}
        </div>
        <div className="row">
          {state.photos.map((pic, index) => (
            <div key={index} className="thumbnail col-md-6 col-xs-12 mb-2">
              <div className="photo">
                <img className="img" src={pic.photoURL} alt="Photos" />
                <div className="col-xs-12 counts-container">
                  <label className="counts">
                    <img
                      src="/images/ic_heart.png"
                      className="icon"
                      alt="Heart"
                    />
                    {pic.likes}
                  </label>
                  <label className="counts">
                    <img
                      src="/images/ic_eye.png"
                      className="icon"
                      alt="Heart"
                    />
                    {pic.views}
                  </label>
                </div>
                <div className="text-right mt-1">
                  <button
                    onClick={() => toggleBG(pic)}
                    className="btn btn-primary mr-1"
                  >
                    {pic.showInBG === "1" ? "Hide in BG" : "Show in BG"}
                  </button>
                  <button
                    onClick={() => deletePhoto(pic)}
                    className="btn btn-danger"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
