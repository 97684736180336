import React, { useState, useEffect, useRef } from "react";
import { AccountService } from "../services/Account.service";
import { IPhoto } from "../models/Amphetrite.models";
import { SideBar } from "../components/SideBar";

export const About = (props: any) => {
  const [about, setAbout] = useState("");

  const getAbout = () => {
    AccountService.getAbout()
      .then(resp => resp.json())
      .then(json => {
        setAbout(json.data[0].about);
      })
      .catch(error => {
        getAbout();
      });
  };

  useEffect(() => {
    getAbout();
  }, [props]);

  return (
    <div className="container-fluid row no-gutters">
      <div className="col-lg-auto">
        <SideBar />
      </div>
      <div className="col-md-9 mt-3">
        <div>
          <h2>About</h2>
        </div>
        <div className="col-md-10 col-xs-12">
          {about.split("\n").map((line: string, index: number) => (
            <p key={index} style={{ color: "#eee" }}>
              {line}
            </p>
          ))}
          <div className="row align-items-center">
            <a
              href="https://www.youtube.com/channel/UCCeTP1OeYdLWpiqxJCtBlDA?view_as=subscriber"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/images/youtube.png"
                alt="Youtube"
                className="icon-big"
              />
            </a>
            <a
              href="https://twitter.com/AdminAmphetrite"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/images/twitter.png"
                alt="Twitter"
                className="image-icon"
              />
            </a>
            <a
              href="https://www.instagram.com/amphetriteadmin"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/images/instagram.webp"
                alt="Instagram"
                style={{ width: 37 }}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
