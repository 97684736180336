import React, { useEffect, useState } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { PhotoService } from "../services/Photo.service";
import { Link } from "react-router-dom";

export const SideBar = (props: any) => {
  const [state, setState] = useState({
    views: 0,
    likes: 0,
  });
  const [profile, setProfile] = useState("");

  useEffect(() => {
    PhotoService.getActiveProfile()
      .then((resp) => resp.json())
      .then((json) => {
        if (json.success) {
          setProfile(json.data[0].photoURL);
        }
      })
      .catch((error) => console.error(error));
    PhotoService.getSummary()
      .then((resp) => resp.json())
      .then((json) => {
        if (json.success) {
          setState({
            ...state,
            views: json.data[0].totalViews,
            likes: json.data[0].totalLikes,
          });
        }
      })
      .catch((error) => {});
  }, [1]);

  return (
    <>
      <div className="d-lg-none">
        <Navbar bg="light" expand="lg">
          <Navbar.Brand href="/"><img src="/images/banner.png" className="banner-img" alt="logo" /></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/portfolio">Portfolio</Nav.Link>
              <Nav.Link href="/events">Events</Nav.Link>
              <Nav.Link href="/about">About</Nav.Link>
              <Nav.Link href="/contact">Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
      <div className="side-bar d-none d-lg-block ">
        {!profile && <div className="profile-photo-blank"></div>}
        {profile && (
          <img src={profile} className="profile-photo" alt="Profile" />
        )}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Link className="nav-brand" to="/">
            <img src="/images/banner.png" className="banner-img" alt="logo" />
          </Link>
          <Link className="nav-link" to="./portfolio">
            Portfolio
          </Link>
          {state.likes && (
            <div className="custom-text">
              <span className="navbar-text mr-2">Appreciations</span>
              <span className="navbar-text">{state.likes}</span>
            </div>
          )}
          {state.views && (
            <div className="custom-text">
              <span className="navbar-text mr-2">Views</span>
              <span className="navbar-text">{state.views}</span>
            </div>
          )}
          <Link className="nav-link" to="./events">
            Events
          </Link>
          <Link className="nav-link" to="./about">
            About
          </Link>
          <Link className="nav-link" to="./contact">
            Contact
          </Link>
        </div>
      </div>
    </>
  );
};
