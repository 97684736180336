import React, {
  useRef,
  useEffect,
  RefObject,
  useState,
  useLayoutEffect,
} from "react";
import { PhotoService } from "../services/Photo.service";
import { IPhoto } from "../models/Amphetrite.models";
import { Carousel } from "react-bootstrap";

export const Gallery = (props) => {
  const { photoCateg } = props.location.state;
  console.log(photoCateg);

  const elemRef = useRef(null);
  const btnRef = useRef(null);
  const [photos, setPhotos] = useState([]);
  const [fullScreen, setFullScreen] = useState(false);

  const expandIcon = "/images/ic_expand.png";
  const collapseIcon = "/images/ic_collapse.png";
  const closeIcon = "/images/ic_close.png";

  useEffect(() => {
    loadImages();
    rotateScreen();
  }, [props]);

  const rotateScreen = () => {
    if (window.screen.width < window.screen.height) {
      window.screen.orientation.lock("landscape-primary").then(console.log).catch(console.error);
    }
  };

  const prevSlide = () => { };

  const nextSlide = () => {
    elemRef.current && elemRef.current.carousel("next");
  };

  const loadImages = () => {
    PhotoService.getPhotosByCategories(photoCateg.categ)
      .then(resp => resp.json())
      .then(json => {
        if (json.success) {
          const urls = json.data;
          setPhotos(urls);
        }
      })
      .catch(error => {
        console.log(error);
        loadImages();
      });
  };

  const toggleFullscreen = () => {
    console.log('toggle', document.custom);
    if (document.custom && document.custom.toggleFullscreen) {
      document.custom.toggleFullscreen();
      window.screen.orientation.lock("landscape-primary").then(console.log).catch(console.error);
      setFullScreen(!fullScreen);
    }
  }

  const close = () => props.history.goBack();

  if (!photos.length) {
    return <div className="">
      Loading...
    </div>;
  }

  const slideChange = (e) => {
    if (photos[e - 1] && photos[e - 1].photoId) {
      PhotoService.updateView(photos[e - 1].photoId);
    }
  }

  const like = (photo) => {
    if (photo.photoId) {
      PhotoService.updateLike(photo.photoId);
      const newPhotos = [...photos];
      newPhotos.find(ph => ph.photoId = photo.photoId).likes = Number(photo.likes) + 1;
      setPhotos(newPhotos);
    }
  }

  const showDetails = () => {
    if (photoCateg.camera || photoCateg.lens || photoCateg.lights || photoCateg.actress || photoCateg.model || photoCateg.location) {
      return true;
    }
    return false;
  };

  return (
    <div ref={elemRef} id="slider">
      <div className="col-xs-12 gallery-btns">
        <button
          className="toggle-button mr-1"
          ref={btnRef}
          type="button"
          onClick={toggleFullscreen}
        >
          <img src={fullScreen ? collapseIcon : expandIcon} alt="expand" className="image-icon" />
        </button>
        <button
          className="toggle-button"
          ref={btnRef}
          type="button"
          onClick={close}
        >
          <img src={closeIcon} alt="expand" className="image-icon" />
        </button>
      </div>
      <Carousel onSelect={slideChange}>
        {showDetails() && (<Carousel.Item>
          <div className="categ-info">
            <h3>{photoCateg.categName}</h3>
            {
              photoCateg.camera && <p><strong>Camera:</strong> {photoCateg.camera}</p>
            }
            {
              photoCateg.lens && <p><strong>Lens:</strong> {photoCateg.lens}</p>
            }
            {
              photoCateg.lights && <p><strong>Lights:</strong> {photoCateg.lights}</p>
            }
            {
              photoCateg.actress && <p><strong>Actress:</strong> {photoCateg.actress}</p>
            }
            {
              photoCateg.model && <p><strong>Model:</strong> {photoCateg.model}</p>
            }
            {
              photoCateg.sareeBy && <p><strong>Saree By:</strong> {photoCateg.sareeBy}</p>
            }
            {
              photoCateg.jewelleyBy && <p><strong>Jewellery By:</strong> {photoCateg.jewelleyBy}</p>
            }
            {
              photoCateg.hm && <p><strong>H&M:</strong> {photoCateg.hm}</p>
            }
            {
              photoCateg.styledBy && <p><strong>Styled By:</strong> {photoCateg.styledBy}</p>
            }
            {
              photoCateg.location && <p><strong>Location:</strong> {photoCateg.location}</p>
            }
            {
              photoCateg.hashTags && <p><strong>Hash Tags:</strong> {photoCateg.hashTags}</p>
            }
          </div>
        </Carousel.Item>)}
        {photos.map((photo, index) => (
          <Carousel.Item key={index}>
            <img src={photo.photoURL} alt="Img" className="carousel-image" />
            <div className="col-xs-5 counts-slide">
              <label className="counts">
                <img
                  src="/images/ic_heart.png"
                  className="icon"
                  onClick={() => like(photo)}
                  style={{ cursor: 'pointer' }}
                  alt="Heart"
                />
                {photo.likes}
              </label>
              <label className="counts">
                <img
                  src="/images/ic_eye.png"
                  className="icon"
                  alt="Heart"
                />
                {photo.views}
              </label>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};
