export interface IBase {
  uniqueID: string;
}

export interface IAccount extends IBase {
  username: string;
  password: string;
}

export interface IPhoto {
  profileId?: number;
  photoId?: number;
  categName: string;
  photoURL: string;
  views: number;
  likes: number;
  active?: string;
  showInBG?: string;
}

export interface ITag {
  metaTagId: string;
  metaTagName: string;
}

export interface IEvent {
  eventId: string;
  eventName: string;
  eventDescription: string;
  startDate: string;
  endDate: string;
  imageURL: string;
  isActive: boolean;
}

export enum EStatus {
  UPLOADING,
  UPLOAD_SUCCESS,
  USERNAME_SUCCESS,
  PASSWORD_SUCCESS,
  DELETE_SUCCESS,
  TOGGLE_SUCCESS,
  IDLE,
}