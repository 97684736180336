import React, { useState, useEffect, useRef } from "react";
import { AccountService } from "../services/Account.service";
import { IPhoto, IEvent, EStatus } from "../models/Amphetrite.models";
import { SideBar } from "../components/SideBar";

const months = ["Jan", "Feb", "Mar", "Apr", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const Events = (props: any) => {
  const categRef = useRef<HTMLInputElement>(null);

  const [state, setState] = useState<{
    error: boolean;
    errorMsg: string;
    status: EStatus;
    events: IEvent[];
  }>({
    error: false,
    status: EStatus.IDLE,
    errorMsg: "",
    events: [],
  });

  const getCategories = () => {
    AccountService.getEvents()
      .then(resp => resp.json())
      .then(json => {
        if (json.success) {
          setState({
            ...state,
            events: json.data,
            status: EStatus.UPLOAD_SUCCESS
          });
        } else {
          setState({
            ...state,
            status: EStatus.UPLOAD_SUCCESS,
            error: true,
            errorMsg: json.message,
          });
        }
      })
      .catch(error => {
        console.error(error);
        setState({
          ...state,
          error: true,
          status: EStatus.UPLOAD_SUCCESS,
          errorMsg: "Network error. Please try again.",
        });
      });
  };

  useEffect(() => {
    getCategories();
  }, [props]);

  const goToCategory = (photoCateg: IPhoto) => {
    props.history.push("/gallery", { photoCateg });
  };

  const formatTime = (time: string) => {
    const date = new Date(time);
    console.log(time, date);
    let meridiam = 'AM IST';
    let hours = date.getHours();
    if (hours > 12) {
      meridiam = 'PM IST';
      hours = hours - 12;
    }
    let minutes = date.getMinutes().toString().padStart(2, '0');
    return `${date.getDate()}-${months[date.getMonth() - 1]}-${date.getFullYear()} ${hours.toString().padStart(2, '0')}:${minutes} ${meridiam}`;
  }

  return (
    <div className="container-fluid row no-gutters">
      <div className="col-lg-auto">
        <SideBar />
      </div>
      <div className="col-md-9 mt-3">
        <div>
          <h2>Events</h2>
        </div>
        <div className="row">
          {state.events.map((pic, index) => (
            <div key={index} className="thumbnail col-md-6 col-xs-12 mb-2">
              <div className="photo">
                <img className="img" src={pic.imageURL} alt="Photos" />
                <div className="col-xs-12 mt-2">
                  <h4><strong>{pic.eventName}</strong></h4>
                  <div className="row p-3 justify-content-between">
                    <span>From: <strong>{formatTime(pic.startDate)}</strong></span>
                    <span>To: <strong>{formatTime(pic.endDate)}</strong></span>
                  </div>
                  <p className="mt-2">{pic.eventDescription}</p>
                </div>
              </div>
            </div>
          ))}
          {state.events.length === 0 && state.status === EStatus.UPLOAD_SUCCESS && (
            <div className="color-white">
              No events scheduled
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
