import React, { useRef, useState, useEffect, RefObject } from "react";
import { NavBar } from "../../components/NavBar";
import { ITag, EStatus } from "../../models/Amphetrite.models";
import { AccountService } from '../../services/Account.service';

export const Tags = (props: any) => {
  const fileRef: RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);
  const docRef: RefObject<HTMLInputElement> = useRef<HTMLInputElement>(null);

  const [state, setState] = useState<{
    error: boolean;
    errorMsg: string;
    tagName: string;
    status: EStatus;
    tags: ITag[];
  }>({
    tags: [],
    error: false,
    tagName: "",
    errorMsg: "",
    status: EStatus.IDLE,
  });

  useEffect(() => {
    loadTags();
  }, [props]);

  const loadTags = () => {
    AccountService.getTags()
      .then((resp) => resp.json())
      .then((json) => {
        if (json.success) {
          setState({
            ...state,
            tags: json.data,
          });
        } else {
          setState({
            ...state,
            error: true,
            tags: [],
            errorMsg: json.message,
          });
        }
      })
      .catch((error) => {
        setState({
          ...state,
          error: true,
          errorMsg: "Network error. Please refresh or press F5...",
        });
      });
  };

  const uploadTag = () => {
    setState({
      ...state,
      error: false,
      status: EStatus.UPLOADING,
    });
    AccountService.addTag(state.tagName)
      .then((resp) => resp.json())
      .then((json) => {
        if (json.success) {
          setState({
            ...state,
            status: EStatus.UPLOAD_SUCCESS,
            tagName: "",
            tags: [...state.tags, json.data],
          });
          // setTimeout(clearStatus, 3000);
        } else {
          setState({
            ...state,
            error: true,
            status: EStatus.IDLE,
            errorMsg: json.message,
          });
        }
      })
      .catch((error) => {
        setState({
          ...state,
          error: true,
          status: EStatus.IDLE,
          errorMsg: "Network error. Please try again...",
        });
      });
  };

  const deleteTag = (tag: ITag) => {
    AccountService.deleteTag(tag.metaTagId)
      .then((resp) => resp.json())
      .then((json) => {
        if (json.success) {
          setState({
            ...state,
            status: EStatus.DELETE_SUCCESS,
          });
          loadTags();
        } else {
          setState({
            ...state,
            error: true,
            errorMsg: json.message,
          });
        }
      })
      .catch((error) => {
        setState({
          ...state,
          error: true,
          status: EStatus.IDLE,
          errorMsg: "Network error. Please try again...",
        });
      });
  };

  const onTextChange = (e: any) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  return (
    <div ref={docRef}>
      <NavBar {...props} />
      <div className="container">
        <div className="jumbotron row mt-3">
          <div className="col-md-6 col-xs-12 form-group">
            <input
              ref={fileRef}
              required
              name="tagName"
              value={state.tagName}
              onChange={onTextChange}
              className="form-control"
              placeholder="Tag"
            />
          </div>
          <div className="col-md-6 col-xs-12 form-group">
            <button
              type="button"
              disabled={state.status === EStatus.UPLOADING}
              onClick={uploadTag}
              className="btn btn-primary btn-block"
            >
              Add Tag
            </button>
          </div>
          {state.error && (
            <label className="ml-3 text-danger">{state.errorMsg}</label>
          )}
          {state.status === EStatus.UPLOADING && (
            <label className="ml-3 text-info">Uploading...</label>
          )}
          {state.status === EStatus.UPLOAD_SUCCESS && (
            <label className="ml-3 text-success">
              Tag added successfully...
            </label>
          )}
          {state.status === EStatus.DELETE_SUCCESS && (
            <label className="ml-3 text-success">
              Tag deleted successfully...
            </label>
          )}
        </div>
        <div className="row">
          {state.tags.map((tag, index) => (
            <div key={index} className="thumbnail col-md-6 col-xs-12 mb-2">
              <div className="row no-gutter bg-white justify-content-between m-2 p-3">
                {tag.metaTagName}
                <button type="button" onClick={() => deleteTag(tag)} className="close" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
