import React from "react";
import { Link } from "react-router-dom";

export const NavBar = (props: any) => {

  
  const logOut = () => {
    const { history } = props;
    // history.goBack(0);
    history.push("/");
    // props.history.go(-(props.history.length - 1))
  };

  return (
    <nav className="navbar navbar-expand-sm bg-dark navbar-dark">
      <Link className="nav-brand" to="./home">
        <img src="/images/banner.png" className="banner-img" alt="logo" />
      </Link>

      <ul className="navbar-nav mr-auto">
        <li className="nav-item" role="presentation">
          <Link className="nav-link" to="./home">
            Gallery
          </Link>
        </li>
        <li className="nav-item" role="presentation">
          <Link className="nav-link" to="./profile">
            Profile
          </Link>
        </li>
        <li className="nav-item" role="presentation">
          <Link className="nav-link" to="./events">
            Events
          </Link>
        </li>
        <li className="nav-item" role="presentation">
          <Link className="nav-link" to="./about">
            About
          </Link>
        </li>
        <li className="nav-item" role="presentation">
          <Link className="nav-link" to="./tags">
            Tags
          </Link>
        </li>
        <li className="nav-item" role="presentation">
          <Link className="nav-link" to="./config">
            Config
          </Link>
        </li>
      </ul>
      <ul className="navbar-nav">
        <li className="nav-item" role="presentation">
          <a className="nav-link" onClick={logOut}>
            Logout
          </a>
        </li>
      </ul>
    </nav>
  );
};
