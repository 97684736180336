import React, { useEffect, useState } from "react";
import { SideBar } from "../components/SideBar";
import { BackgroundImage } from "../components/BackgroundImage";
import { IAccount, IPhoto } from "../models/Amphetrite.models";
import { PhotoService } from "../services/Photo.service";

export const Home = (props: any) => {
  const [urls, setUrls] = useState([]);

  const loadRandomImages = () => {
    console.log('Loading random images');
    
    PhotoService.getRandom()
      .then(resp => resp.json())
      .then(json => {
        if (json.success) {
          const urls = json.data.map((pic: IPhoto) => pic.photoURL);
          setUrls(urls);
        }
      }).catch(error => {
        console.log(error);
        loadRandomImages();
      });
  };

  useEffect(() => {
    console.log(props);
    
    loadRandomImages();
  }, [props]);

  return (
    <div className="container-fluid">
      <div className="side-bar-overlay col-lg-2 no-padding">
        <SideBar />
      </div>
      <BackgroundImage image={urls} />
    </div>
  );
};
