import React, { useState, useEffect } from "react";
import { NavBar } from "../../components/NavBar";
import { AccountService } from "../../services/Account.service";

export const AdminAbout = (props: any) => {
  const [state, setState] = useState({
    aboutId: 0,
    about: "",
    error: false,
    errorMsg: "",
    success: false,
  });

  useEffect(() => {
    AccountService.getAbout()
      .then(resp => resp.json())
      .then(json => {
        setState({
          ...state,
          about: json.data[0].about,
          aboutId: json.data[0].aboutId,
        })
        
      })
      .catch(error => {
        setState({
          ...state,
          error: true,
          errorMsg: "Network error. Please try again later...",
        });
      });
  }, [props]);

  const onTextChange = (e: any) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const onSave = () => {
    setState({
      ...state,
      error: false,
      success: false,
    });
    if (state.aboutId) {
      AccountService.updateAbout(state.aboutId, state.about)
        .then(resp => resp.json())
        .then(json => {
          if (json.success) {
            setState({
              ...state,
              success: true,
            });
          } else {
            setState({
              ...state,
              error: true,
              errorMsg: json.message,
            });
          }
        })
        .catch(error => {
          setState({
            ...state,
            error: true,
            errorMsg: "Network error. Please try again later...",
          });
        });
    } else {
      AccountService.createAbout(state.about)
        .then(resp => resp.json())
        .then(json => {
          if (json.success) {
            setState({
              ...state,
              success: true,
              aboutId: json.data[0].aboutId,
            });
          } else {
            setState({
              ...state,
              error: true,
              errorMsg: json.message,
            });
          }
        })
        .catch(error => {
          setState({
            ...state,
            error: true,
            errorMsg: "Network error. Please try again later...",
          });
        });
    }
  };

  return (
    <div className="container-fluid">
      <NavBar {...props} />
      <div className="container jumbotron">
        <div className="form-group">
          <textarea
            name="about"
            onChange={onTextChange}
            value={state.about}
            className="form-control"
            style={{ height: 300 }}
          />
        </div>
        <div className="form-group">
          <button onClick={onSave} type="button" className="btn btn-primary">
            Save
          </button>
        </div>
        {state.error && (
          <label className="ml-3 text-danger">{state.errorMsg}</label>
        )}
        {state.success && (
          <label className="ml-3 text-success">
            Content updated successfully...
          </label>
        )}
      </div>
    </div>
  );
};
