import React, { useState } from "react";
import { NavBar } from "../../components/NavBar";
import { AccountService } from "../../services/Account.service";
import { EStatus } from "../../models/Amphetrite.models";

export const Config = (props: any) => {
  const [state, setState] = useState<{
    username: string;
    newUsername: string;
    password: string;
    newPassword: string;
    confirmPassword: string;
    error: boolean;
    status: EStatus;
    errorMsg: string;
  }>({
    username: "",
    newUsername: "",
    password: "",
    newPassword: "",
    confirmPassword: "",
    error: false,
    errorMsg: "",
    status: EStatus.IDLE,
  });

  const updateUsername = (e: any) => {
    setState({
      ...state,
      error: false,
      status: EStatus.IDLE,
    });
    e.preventDefault();
    AccountService.updateUsername(state.username, state.newUsername)
      .then(resp => resp.json())
      .then(json => {
        if (json.success) {
          setState({
            ...state,
            username: "",
            newUsername: "",
            status: EStatus.USERNAME_SUCCESS,
          });
        } else {
          setState({
            ...state,
            error: true,
            errorMsg: json.message,
          });
        }
      })
      .catch(error => {
        console.error(error);
        setState({
          ...state,
          error: true,
          errorMsg: "Network error. Please try again.",
        });
      });
  };

  const updatePassword = (e: any) => {
    setState({
      ...state,
      error: false,
      status: EStatus.IDLE,
    });
    e.preventDefault();
    AccountService.updatePassword(state.username, state.password, state.newPassword)
      .then(resp => resp.json())
      .then(json => {
        if (json.success) {
          setState({
            ...state,
            username: "",
            password: "",
            newPassword: "",
            status: EStatus.PASSWORD_SUCCESS,
          });
        } else {
          setState({
            ...state,
            error: true,
            errorMsg: json.message,
          });
        }
      })
      .catch(error => {
        console.error(error);
        setState({
          ...state,
          error: true,
          errorMsg: "Network error. Please try again.",
        });
      });
  };

  const onTextChange = (e: any) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  return (
    <div>
      <NavBar {...props} />
      <div className="container mt-4 col-md-6 col-xs-12">
        <div className="card">
          <div className="card-header">Change Username</div>
          <div className="card-body">
            <form onSubmit={updateUsername}>
              <div className="form-group">
                <input
                  required
                  className="form-control"
                  value={state.username}
                  onChange={onTextChange}
                  name="username"
                  placeholder="Current username"
                />
              </div>
              <div className="form-group">
                <input
                  required
                  className="form-control"
                  value={state.newUsername}
                  onChange={onTextChange}
                  name="newUsername"
                  placeholder="New username"
                />
              </div>
              <div className="form-group">
                <button type="submit" className="btn btn-primary">
                  Update
                </button>
              </div>
            </form>
          </div>
          <div className="card-footer">
            {state.error && (
              <label className="text-danger">{state.errorMsg}</label>
            )}
            {state.status === EStatus.USERNAME_SUCCESS && (
              <label className="ml-3 text-success">
                Username updated successfully...
              </label>
            )}
          </div>
        </div>
        <div className="card mt-5">
          <div className="card-header">Update Password</div>
          <div className="card-body">
            <form onSubmit={updatePassword}>
              <div className="form-group">
                <input
                  required
                  className="form-control"
                  value={state.username}
                  onChange={onTextChange}
                  name="username"
                  placeholder="Username"
                />
              </div>
              <div className="form-group">
                <input
                  required
                  type="password"
                  className="form-control"
                  value={state.password}
                  onChange={onTextChange}
                  name="password"
                  placeholder="Current password"
                />
              </div>
              <div className="form-group">
                <input
                  required
                  type="password"
                  className="form-control"
                  value={state.newPassword}
                  onChange={onTextChange}
                  name="newPassword"
                  placeholder="New Password"
                />
              </div>
              <div className="form-group">
                <button type="submit" className="btn btn-primary">
                  Update
                </button>
              </div>
            </form>
          </div>
          <div className="card-footer">
            {state.error && (
              <label className="text-danger">{state.errorMsg}</label>
            )}
            {state.status === EStatus.PASSWORD_SUCCESS && (
              <label className="ml-3 text-success">
                Password updated successfully...
              </label>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
