import React, { useState, useEffect } from "react";
import { loadReCaptcha, ReCaptcha } from "react-recaptcha-v3";
import { AccountService } from "../services/Account.service";
import { SideBar } from "../components/SideBar";

export const Contact = (props: any) => {
  const reCaptchaSiteKey = "6LdbP90UAAAAAFbCXThIEBnOFKgmJGxbav64KJxs";
  const [state, setState] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    success: false,
    errorMessage: "",
    error: false,
    token: "",
  });

  useEffect(() => {
    loadReCaptcha(reCaptchaSiteKey)
  }, [state.token]);

  const onTextChange = (e: any) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const sendMessage = async (e: any) => {
    e.preventDefault();
    const response = await AccountService.sendMessage(
      state.name,
      state.email,
      state.subject,
      state.message,
      state.token
    );
    const json = await response.json();
    if (json.success) {
      setState({
        ...state,
        name: "",
        email: "",
        subject: "",
        message: "",
        token: '',
        success: true,
      });
      
    } else {
      setState({
        ...state,
        error: true,
        errorMessage: json.errors,
      });
    }
    setTimeout(() => {
      setState({
        ...state,
        name: "",
        email: "",
        subject: "",
        message: "",
        token: '',
        success: false,
        error: false,
      });
      window.location.href = '/contact';
    }, 3000);
  };

  const verifyCallback = (token: string) => {
    setState({
      ...state,
      token,
    });
  };
  return (
    <div className="container-fluid row no-gutters">
      <div className="col-lg-auto">
        <SideBar />
      </div>
      <div className="col-md-8 offset-md-1 mt-3">
        <div className="row contact-area-details wow fadeIn">
          <div className="contact-from-bg"></div>
          <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
            <div className="footer-address-details" style={{ marginBottom: 0 }}>
              <h3>Contact Us</h3>
              <p>
                <span>E-MAIL :</span>{" "}
                <a href="mailto:admin@amphetrite.com">admin@amphetrite.com</a>
              </p>
              <p>
                <span>PHONE :</span>{" "}
                <a href="callto:+918593031503">+91 8593 03 15 03</a>
              </p>
              <p>
                <span>ADDRESS :</span> Swastik, Beta-6c-Yellow, Gokulam
                Harmonia, Kangarappady, Kakkanad, Kochi - 682021
              </p>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
            <div className="contact-form-area">
              <div className="contact-form">
                {state.success && (
                  <label className="ml-3 text-color">
                    Thank you!!! We will connect back to you soon.
                  </label>
                )}
                {state.error && (
                  <label className="ml-3 text-danger">
                    {state.errorMessage}
                  </label>
                )}
                <form onSubmit={sendMessage}>
                  <div className="form-group">
                    <div className="form-input">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        required
                        value={state.name}
                        onChange={onTextChange}
                        placeholder="Name..."
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-input">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        required
                        value={state.email}
                        onChange={onTextChange}
                        placeholder="Email..."
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-input">
                      <input
                        type="text"
                        required
                        className="form-control"
                        name="subject"
                        value={state.subject}
                        onChange={onTextChange}
                        placeholder="Subject..."
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-input">
                      <textarea
                        required
                        className="form-control"
                        name="message"
                        value={state.message}
                        onChange={onTextChange}
                        placeholder="Message..."
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <ReCaptcha
                      verifyCallback={verifyCallback}
                      sitekey={reCaptchaSiteKey}
                      action="contact"
                    />
                  </div>
                  <div className="form-group">
                    <button className="btn btn-primary" type="submit">
                      Send
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
