import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Home } from "../screens/Home";
import { Portfolio } from "../screens/Portfolio";
import { Gallery } from "../screens/Gallery";
import { About } from "../screens/About";
import { Contact } from "../screens/Contact";
import { Events } from "../screens/Events";
import { AdminHome } from "../screens/admin/Home";
import { AdminAbout } from "../screens/admin/AdminAbout";
import { Category } from "../screens/admin/Category";
import { Login } from "../screens/admin/Login";
import { Profile } from "../screens/admin/Profile";
import { Config } from "../screens/admin/Config";
import { Tags } from "../screens/admin/Tags";
import { AdminEvents } from "../screens/admin/Events";

export const Router = () => {
  return (
    <BrowserRouter>
      <div>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/portfolio" component={Portfolio} />
          <Route exact path="/gallery" component={Gallery} />
          <Route exact path="/about" component={About} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/events" component={Events} />
          <Route exact path="/admin/" component={Login} />
          <Route exact path="/admin/home" component={AdminHome} />
          <Route exact path="/admin/about" component={AdminAbout} />
          <Route exact path="/admin/photoCategory" component={Category} />
          <Route exact path="/admin/profile" component={Profile} />
          <Route exact path="/admin/config" component={Config} />
          <Route exact path="/admin/tags" component={Tags} />
          <Route exact path="/admin/events" component={AdminEvents} />
        </Switch>
      </div>
    </BrowserRouter>
  );
};
