import React from "react";
import { Carousel } from "react-bootstrap";

interface Props {
  image: string[];
}

export const BackgroundImage: React.FC<Props> = (props: Props) => {

  return (
    <Carousel indicators={false} controls={false} fade={true}>
      {props.image.map((photo, index) => (
        <Carousel.Item key={index}>
          <img src={photo} alt="Img" className="carousel-image-bg" />
        </Carousel.Item>
      ))}
    </Carousel>
  );
}
