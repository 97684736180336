import React, { useState } from "react";
import {AccountService} from "../../services/Account.service";

export const Login = (props: any) => {
  const [state, setState] = useState({
    username: "",
    password: "",
  });

  const onTextChange = (e: any) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const onLogin = async (e: any) => {
    e.preventDefault();
    const response = await AccountService.login(state.username, state.password);
    const json = await response.json();
    console.log(json);
    
    if (json.success) {
      props.history.push('/admin/home');
    }    
  };

  return (
    <div className="container">
      <div className="row contact-area-details wow fadeIn">
        <div className="contact-from-bg"></div>
        <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
          <div className="footer-address-details" style={{ marginBottom: 0 }}>
            <img className="img" src="../images/SmallLogo.jpg" alt="Logo" />
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
          <div className="contact-form-area">
            <div className="contact-form">
              <form onSubmit={onLogin}>
                <div className="form-group">
                  <div className="form-input">
                    <input
                      type="text"
                      className="form-control"
                      name="username"
                      value={state.username}
                      onChange={onTextChange}
                      placeholder="Username..."
                    />
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-input">
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      value={state.password}
                      onChange={onTextChange}
                      placeholder="Password..."
                    />
                  </div>
                </div>
                <div className="form-group">
                  <button
                    className="btn btn-primary"
                    type="submit"
                  >
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
