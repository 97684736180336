import { IAccount } from "../models/Amphetrite.models";
import { RestService } from "./Rest.service";

export class AccountService extends RestService {
  /**
   * login
   */
  public static login(username: string, password: string) {
    return this.post("login.php", { username, password });
  }

  public static updateUsername(username: string, newUsername: string) {
    return this.post("accountService.php", {
      username,
      newUsername,
      rType: "updateUsername",
    });
  }

  public static updatePassword(
    username: string,
    currentPassword: string,
    newPassword: string
  ) {
    return this.post("accountService.php", {
      username,
      currentPassword,
      newPassword,
      rType: "updatePassword",
    });
  }

  public static getProfilePhotos() {
    return this.post("PhotoService.php", {
      rType: "profile",
    });
  }

  public static activateProfile(profileId?: number) {
    return this.post("PhotoService.php", {
      rType: "activate_profile",
      profileId,
    });
  }

  public static createAbout(about: string) {
    return this.post("accountService.php", {
      rType: "createAbout",
      about,
    });
  }

  public static updateAbout(aboutId: number, about: string) {
    return this.post("accountService.php", {
      rType: "updateAbout",
      about,
      aboutId,
    });
  }

  public static getAbout() {
    return this.post("accountService.php", {
      rType: "getAbout",
    });
  }

  public static uploadPhoto(photo: object) {
    return this.post("profileUpload.php", photo);
  }

  public static deletePhoto(photoId: number | undefined, fileName: string) {
    fileName = fileName.substr(fileName.lastIndexOf("/") + 1);
    return this.post("deleteProfile.php", { photoId, fileName });
  }

  public static sendMessage(name: string, email: string, phone: string, message: string, token: string) {
    return this.post("process.php", {
      name, 
      email,
      phone,
      message,
      token,
    });
  }

  public static getTags() {
    return this.post("accountService.php", {
      rType: "getTags",
    });
  }

  public static addTag(tagName: string) {
    return this.post("accountService.php", {
      rType: "addTag",
      tagName
    });
  }

  public static deleteTag(tagId: string) {
    return this.post("accountService.php", {
      rType: "deleteTag",
      tagId
    });
  }

  public static getEvents() {
    return this.post("accountService.php", {
      rType: "getEvents"
    });
  }

  public static deleteEvent(eventId: string) {
    return this.post("accountService.php", {
      rType: "deleteEvent",
      eventId,
    });
  }

  public static uploadEvent(event: object) {
    return this.post("eventUpload.php", event);
  }
}
