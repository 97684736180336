export class RestService {
  public static BASE_URL = "/services/";

  protected static post(service: string, data: any) {
    const body = new FormData();
    Object.keys(data).forEach((key: string) => body.append(key, data[key]));
    return fetch(this.BASE_URL + service, {
      method: "POST",
      body,
    });
  }

  protected static get(service: string, params?: string) {
    return fetch(this.BASE_URL + service + "?" + params);
  }
}
