import { RestService } from "./Rest.service";

export class PhotoService extends RestService {
  public static getCategories() {
    return this.post("PhotoService.php", {
      rType: "categories",
    });
  }

  public static getRandom() {
    return this.post("PhotoService.php", {
      rType: "random",
    });
  }

  public static getSummary() {
    return this.post("PhotoService.php", {
      rType: "summary",
    });
  }

  public static getPhotosByCategories(category: string) {
    return this.post("PhotoService.php", {
      rType: "photosByCategories",
      category,
    });
  }

  public static deleteCategory(categId: number) {
    return this.post("PhotoService.php", {
      rType: "deleteCateg",
      categId,
    });
  }

  public static uploadPhoto(photo: object) {
    return this.post("photoUpload.php", photo);
  }

  public static deletePhoto(photoId: number | undefined, fileName: string) {
    fileName = fileName.substr(fileName.lastIndexOf("/") + 1);
    return this.post("deletePhoto.php", { photoId, fileName });
  }

  public static getActiveProfile() {
    return this.post("PhotoService.php", { rType: "active_profile" });
  }

  public static newCategory(categ: object) {
    return this.post("PhotoService.php", {
      rType: "create_categ",
      ...categ,
    });
  }

  public static toggleBG(photoId: number, value: string) {
    return this.post("PhotoService.php", {
      rType: "toggle_bg",
      photoId,
      value
    });
  }

  public static updateView(photoId: number) {
    return this.post("PhotoService.php", {
      rType: "update_view",
      photoId,
    });
  }
  public static updateLike(photoId: number) {
    return this.post("PhotoService.php", {
      rType: "update_like",
      photoId,
    });
  }
}
