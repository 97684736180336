import React, { useState, useEffect, useRef } from "react";
import { NavBar } from "../../components/NavBar";
import { PhotoService } from "../../services/Photo.service";
import { IPhoto } from "../../models/Amphetrite.models";
import { findAllByLabelText } from "@testing-library/react";

export const AdminHome = (props: any) => {
  console.log(props);

  const categRef = useRef<HTMLInputElement>(null);

  const [state, setState] = useState<{
    error: boolean;
    success: boolean;
    errorMsg: string;
    categories: IPhoto[];
    categName: string;
    camera: string;
    lens: string;
    light: string;
    actress: string;
    model: string;
    sareeBy: string;
    jewelleyBy: string;
    hm: string;
    styledBy: string;
    location: string;
    hashTags: string;
  }>({
    error: false,
    success: false,
    errorMsg: "",
    categories: [],
    categName: "",
    camera: "",
    lens: "",
    light: "",
    actress: "",
    model: "",
    sareeBy: "",
    jewelleyBy: "",
    hm: "",
    styledBy: "",
    location: "",
    hashTags: "",
  });

  const getCategories = () => {
    PhotoService.getCategories()
      .then(resp => resp.json())
      .then(json => {
        if (json.success) {
          setState({
            ...state,
            success: false,
            categories: json.data,
          });
        } else {
          setState({
            ...state,
            error: true,
            success: false,
            categories: [],
            errorMsg: json.message,
          });
        }
      })
      .catch(error => {
        console.error(error);
        setState({
          ...state,
          error: true,
          success: false,
          errorMsg: "Network error. Please try again.",
        });
      });
  };

  useEffect(() => {
    getCategories();
  }, [props]);

  const onTextChange = (e: any) => {
    const { name, value } = e.target;
    setState({
      ...state,
      [name]: value,
    });
  };

  const newCategory = (e: any) => {
    e.preventDefault();
    const { error, errorMsg, categories, ...categ } = state;
    PhotoService.newCategory(categ)
      .then(resp => resp.json())
      .then(json => {
        if (json.success) {
          setState({
            ...state,
            success: true,
            categName: "",
            camera: "",
            lens: "",
            light: "",
            actress: "",
            model: "",
            sareeBy: "",
            jewelleyBy: "",
            hm: "",
            styledBy: "",
            location: "",
            hashTags: "",
          });
          getCategories();
        } else {
          setState({
            ...state,
            error: true,
            success: false,
            errorMsg: json.message,
          });
        }
      })
      .catch(error => {
        console.error(error);
        setState({
          ...state,
          error: true,
          success: false,
          errorMsg: "Network error. Please try again.",
        });
      });
  };

  const deleteCateg = (category: any) => {
    PhotoService.deleteCategory(category.categId)
      .then(resp => resp.json())
      .then(json => {
        if (json.success) {
          setState({
            ...state,
            success: true,
          });
          getCategories();
        } else {
          setState({
            ...state,
            error: true,
            success: false,
            errorMsg: json.message,
          });
        }
      })
      .catch(error => {
        console.error(error);
        setState({
          ...state,
          error: true,
          success: false,
          errorMsg: "Network error. Please try again.",
        });
      });
  };

  const goToCategory = (photoCateg: IPhoto) => {
    props.history.push("/admin/photoCategory", { photoCateg });
  };

  return (
    <div>
      <NavBar {...props} />
      <div className="container">
        <form onSubmit={newCategory} className="jumbotron row mt-3">
          <div className="col-md-4 col-xs-12 form-group">
            <input
              ref={categRef}
              required
              type="text"
              name="categName"
              value={state.categName}
              onChange={onTextChange}
              className="form-control"
              placeholder="Category"
            />
          </div>
          <div className="col-md-4 col-xs-12 form-group">
            <input
              ref={categRef}
              type="text"
              name="camera"
              value={state.camera}
              onChange={onTextChange}
              className="form-control"
              placeholder="Camera"
            />
          </div>
          <div className="col-md-4 col-xs-12 form-group">
            <input
              ref={categRef}
              type="text"
              name="lens"
              value={state.lens}
              onChange={onTextChange}
              className="form-control"
              placeholder="Lens"
            />
          </div>
          <div className="col-md-4 col-xs-12 form-group">
            <input
              ref={categRef}
              type="text"
              name="light"
              value={state.light}
              onChange={onTextChange}
              className="form-control"
              placeholder="Light"
            />
          </div>
          <div className="col-md-4 col-xs-12 form-group">
            <input
              ref={categRef}
              type="text"
              name="actress"
              value={state.actress}
              onChange={onTextChange}
              className="form-control"
              placeholder="Actress"
            />
          </div>
          <div className="col-md-4 col-xs-12 form-group">
            <input
              ref={categRef}
              type="text"
              name="model"
              value={state.model}
              onChange={onTextChange}
              className="form-control"
              placeholder="Model"
            />
          </div>
          <div className="col-md-4 col-xs-12 form-group">
            <input
              ref={categRef}
              type="text"
              name="sareeBy"
              value={state.sareeBy}
              onChange={onTextChange}
              className="form-control"
              placeholder="Saree By"
            />
          </div>
          <div className="col-md-4 col-xs-12 form-group">
            <input
              ref={categRef}
              type="text"
              name="jewelleyBy"
              value={state.jewelleyBy}
              onChange={onTextChange}
              className="form-control"
              placeholder="Jewellery By"
            />
          </div>
          <div className="col-md-4 col-xs-12 form-group">
            <input
              ref={categRef}
              type="text"
              name="hm"
              value={state.hm}
              onChange={onTextChange}
              className="form-control"
              placeholder="H&M"
            />
          </div>
          <div className="col-md-4 col-xs-12 form-group">
            <input
              ref={categRef}
              type="text"
              name="styledBy"
              value={state.styledBy}
              onChange={onTextChange}
              className="form-control"
              placeholder="Styled By"
            />
          </div>
          <div className="col-md-4 col-xs-12 form-group">
            <input
              ref={categRef}
              type="text"
              name="location"
              value={state.location}
              onChange={onTextChange}
              className="form-control"
              placeholder="Location"
            />
          </div>
          <div className="col-md-4 col-xs-12 form-group">
            <input
              ref={categRef}
              type="text"
              name="hashTags"
              value={state.hashTags}
              onChange={onTextChange}
              className="form-control"
              placeholder="Hash tags"
            />
          </div>
          <div className="col-md-3 col-xs-12 form-group">
            <button type="submit" className="btn btn-primary btn-block">
              Create New Category
            </button>
          </div>
          {state.error && <label className="ml-3 text-danger">{state.errorMsg}</label>}
          {state.success && <label className="ml-3 text-success">Action successfull...</label>}
        </form>
        <div className="row">
          {state.categories.map((category, index) => (
            <div
              key={index}
              
              className="thumbnail col-md-6 col-xs-12 mb-2"
            >
              <div className="category">
                <img className="img" src={category.photoURL} alt="Photos" />
                <div onClick={() => goToCategory(category)} className="row">
                  <div className="col-md-6 col-xs-12 mt-3">
                    <label className="">{category.categName}</label>
                  </div>
                  <div className="col-md-6 col-xs-12 counts-container mt-3">
                    <label className="counts">
                      <img
                        src="/images/ic_heart.png"
                        className="icon"
                        alt="Heart"
                      />
                      {category.likes || "0"}
                    </label>
                    <label className="counts">
                      <img
                        src="/images/ic_eye.png"
                        className="icon"
                        alt="Heart"
                      />
                      {category.views || "0"}
                    </label>
                  </div>
                </div>
                <div className="">
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => deleteCateg(category)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
